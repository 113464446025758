.App {
  text-align: center;
}

.AAAAA {
  text-align: center;
}

.imgs {
  width: 25%;
  height: auto;
}


.qr {
  width: 10%;
  height: auto;
}
